html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', serif !important;
  // background-color: #201854 !important;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}
.copy{
  cursor: pointer;
}
.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}


ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

h1 {
  font-size: 80px !important;
  font-style: normal !important;
}

.grey {
  color: #8CA0B9 !important;
}


.ptb20 {
  padding: 20px 0;
}

h2 {
  font-size: 42px !important;
  font-style: normal !important;
}

p {
  font-size: 16px !important;
}

.btn-common {
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 10px;
  margin: 10px 0;
  padding: 12px 30px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-size: 13px;
  width: 100%;
}

.btn-common1 {
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 10px;
  margin: 10px 0;
  padding: 12px 30px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-size: 13px;
  width: 100%;
  pointer-events: none;
  opacity: 0.6;
}



.ptb {
  padding: 60px 0;
}

.yellow {
  color: #E09414;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #E09414;
}

.green {
  color: #6DFF39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}


.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    outline: none;

    &:focus {
      outline: none;
    }

    .form-inline {

      flex-flow: unset !important;

      /* align-items: center; */
      flex-direction: column !important;
      justify-content: start !important;
      /* text-align: left; */
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  .main-about .main-tile .inner-tile li {
    padding-right: 0px;
  }

  .main-team {
    .inner-content {
      .carousel-control-next {
        right: 0 !important;
        left: 40px !important;
        top: -44px !important;
        height: 30px;
      }

      .carousel-control-prev {
        right: 0 !important;
        left: 0px !important;
        top: -44px !important;
        height: 30px;
      }
    }

  }

  // .main-navbar {
  //   .modal-dialog {
  //     position: relative;
  //     width: 48%;
  //     margin: .5rem;
  //     pointer-events: none;
  // }
  // }


  .main-about {
    .inner-img {
      img {
        margin: 20px auto !important;
      }
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
    text-align: center;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }


  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
}

// 12 pro max 
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }


  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
}

// .main_box{
//   background: #FFFFFF;
//   box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
//   border-radius: 30px; 
//   width: 24%;
//   margin: auto;
// }
.reset_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  color: #030229;
}

.reset_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #656565;
}

.bar_bar_main {
  display: flex;
}

.bar_bar {
  width: 16%;
  height: 4px;
  margin-top: 5px;
  margin-bottom: 18px;
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 4px;
}

.bar_bar1 {
  margin-left: 5px;
  width: 3%;
  height: 4px;
  margin-top: 5px;
  margin-bottom: 18px;
  background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  border-radius: 4px;
}


.verify_text {
  font-size: 14px !important;
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}


.swal-title {

  font-size: 20px !important;

}

.swal-modal {
  width: 400px !important
}

.swal-footer {
  text-align: center
}


.first_first_text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #222 !important;
  // color: red !important;
  // white-space: nowrap;
}

.ref_ref_ref {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  color: #515151;
  // color: red;
}

.blue {
  color: #3456D0 !important;
  text-align: center;

  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.code_btn_btn {

  background: #FFFFFF;
  // background: red;
  border-radius: 10px;
  padding: 15px 30px;
  border: none !important;

}

.main_main_main {
  // background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
  min-height: 100vh;
  background: url(../src/Assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mainparent {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 443px;
  width: 100%;
  margin: 25px auto 70px;

  input {
    border-radius: 10px;
    border: 1.5px solid #E1ECFF;
    background: #FFF;
    padding: 18px;
    width: 100%;
    max-width: 312px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    &::placeholder {
      color: var(--primary-blue, #3456D0);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .inputspan {
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    top: -8px;
    left: 10px;
    background: #FFF;
  }

  .copy {
    border-radius: 10px;
    background: #094ECD;
    padding: 18px 40px;
    width: 100%;
    max-width: 123px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }
}

.jsjdhdjh {
  padding-bottom: 40px;
}

.main_main_div_copy {

  display: flex;
  justify-content: center;
  align-items: center;

}

.copsssssssss {
  cursor: pointer !important;
}